export enum ChatModel {
    //OpenAI
    GPT_4o = 'gpt-4o',
    GPT_4o_mini = 'gpt-4o-mini',
    O1 = 'o1',
    O3_mini = 'o3-mini',

    //Anthropic
    CLAUDE_3_7_SONNET = 'claude-3-7-sonnet-20250219',

    //Google
    GEMINI_1_5_PRO = 'gemini-1.5-pro-latest',
    GEMINI_2_0_FLASH = 'gemini-2.0-flash',
    GEMINI_2_0_FLASH_LITE = 'gemini-2.0-flash-lite',

    //OVH
    MISTRAL_7B = 'Mistral-7B-Instruct-v0.2',
    LLAMA_3_3_70B = 'Meta-Llama-3_3-70B-Instruct',
    OVH_MIXTRAL_8X7B = 'Mixtral-8x7B-Instruct-v0.1',
}
