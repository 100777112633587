import { ChatModel } from '../app/chat/chat-model.enum';

export const environment = {
    apiUrl: '/api',
    defaultApi: 'https://app.owlatwork.com/api',

    stripePublishKey:
        'pk_live_51HFxUsLejMtS2pDBJE44rSOaEuCB1qmZgbHIWotxalV3AZVzWLxUQOLaZV9yL5zJQBfAhzaqTok39YA4dLpzsDOR006AXJcrVc',
    stripeDefaultPrice: 'price_1OHnxLLejMtS2pDB2R4DKKLX',

    availableModels: [
        {
            model: ChatModel.GPT_4o,
            label: 'GPT-4o',
            manufacturer: 'Open AI',
            description: 'Fast, intelligent, flexible GPT model.',
        },
        /*
        {
            model: ChatModel.GPT_4o_mini,
            label: 'GPT-4o-mini',
            manufacturer: 'Open AI',
            description: 'Fast, small model for focused tasks.',
        },
        */
        {
            model: ChatModel.O1,
            label: 'O1',
            manufacturer: 'Open AI',
            description: 'High-intelligence reasoning model.',
        },
        {
            model: ChatModel.O3_mini,
            label: 'O3-mini',
            manufacturer: 'Open AI',
            description: 'Fast, flexible, intelligent reasoning model.',
        },
        {
            model: ChatModel.CLAUDE_3_7_SONNET,
            label: 'Claude 3.7 Sonnet',
            manufacturer: 'Anthropic',
            description: 'High-quality, context-aware language model.',
        },
        {
            model: ChatModel.GEMINI_2_0_FLASH,
            label: 'Gemini 2.0 Flash',
            manufacturer: 'Google',
            description: 'Powerful model with low latency and enhanced performance.',
        },
        /*
        {
            model: ChatModel.GEMINI_2_0_FLASH_LITE,
            label: 'Gemini 2.0 Flash Lite',
            manufacturer: 'Google',
            description: '',
        },
        */
        {
            model: ChatModel.LLAMA_3_3_70B,
            label: 'Llama 3.3 70B',
            manufacturer: 'Meta',
            description: 'Powerful open-source model.',
        },
    ],
};
